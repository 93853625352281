@import url('https://fonts.googleapis.com/css2?family=Karla&family=Merriweather&family=Poppins&family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', 'Veranda', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3eddf;
  color: #4f4f4f;
  letter-spacing: 2px;
}

code {
  font-family: 'Merriweather', 'Courier New', monospace;
}

#root {
  margin: 0;
  padding: 0;
  }