.App {
  text-align: center;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Karla', sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
}

div {
  margin: auto;
  padding: 5px 20px;
}


a {
  text-decoration: none;
}

p {
  letter-spacing: normal;
}

hr {
  margin: 20px 100px;
  color: #4f4f4f;
}

.section-title {
  padding: 20px;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 2px;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

/* SECTIONS */

#navigation {
  background-color: #f3eddf;
  color: #4f4f4f;
}

#projects {
  background-color: #f8f5ef;
  padding-top: 20px;
}

#header {
  background-color: white;
}

#blog {
  margin: 30px 0px;
}

#about {
  background-color: #9BA6A1;
  letter-spacing: normal;
}

#resume {
  background-color: #9BA6A1;
  color: white;
  padding-bottom: 30px;
}

#contact {
  background-color: white;
}

#footer {
  background-color: #f8f5ef;
}


/* ROWS */

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}

.row-two-head {
  padding: 20px;
  margin: 0;
  width: 360px;
}

.row-two-con {
  padding: 20px;
  margin: 0;
  width: 160px;
}

.row-three {
  margin: 10px;
  padding: 20px;
  width: 300px;
}

.row-four {
  margin: 10px;
  padding: 20px;
  width: 260px;
}

/* CARDS */

.card-img {
  display: block;
  width: 100%;
  height: 15rem;
  margin: 0;
  object-fit: cover;
  }

.card-header {
  display: flex;
  width: 100%;
  color: black;
  text-align: left;
  margin: 0px;
  padding: 0px;
}

.card-link{ 
  color: white;
  font-size: 32px;
  text-decoration: none;
  text-transform: uppercase;
}

.card-project {
  position: relative;
}

.card-project h3{
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
  text-align:center;
  color: white;
  opacity:0;
  transition:.3s;
}

.card-body {
  background-color: white;
  color: #4f4f4f;
  font-family: 'Poppin', sans-serif;
  text-align: left;  
  margin: 0px;
  padding: 10px 20px;
}

.card-body > h4 {
  font-weight: 600;
}

.card-art {
  height: 7rem;
}

.card-footer {
  background-color: white;
  color: #4f4f4f;
  font-family: 'Poppin', sans-serif;
  font-size: smaller;
  text-align: left;  
  margin: 0px;
  padding: 10px 20px;
}

.posted {
  text-transform: uppercase;
  font-size: smaller;
}

/* CARD EFFECTS */

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover, .hvr-grow:focus, .hvr-grow:active {
  -webkit-transform: scale(1.03,1.03);
  transform: scale(1.03,1.03);
}

/* NAVIGATION */

.navbar {
  display: flex;
  background-color: #f3eddf;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  height: 80px;
  overflow: hidden;
}

.nav-name {
  display: block;
  align-items: center;
  justify-content: space-evenly;
  margin: 10px 10px;
}

.nav-name > p {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 14px;
}

.nav-links {
  float: right;
  margin: 10px;
}

.nav-links a {
  padding: 10px 12px;
}

.nav-links li {
  float: right;
}

.option {
  color: #4f4f4f;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
}

.option a {
  color: #4f4f4f;
}

.option:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.nav-options {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.mobile-menu {
  display: none;
}

@media screen and (max-width: 750px) {
  .mobile-menu {
    display: block;
  }

  .nav-options {
    display: flex;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    padding: 60px 40px;
  }

  .nav-options.active {
    background: #f3eddf;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding: 5px 0px;
    margin: 10px 0px;
  }

  li {
    padding: 20px 10px;
  }

  .menu-icon {
    color: #4f4f4f;
    float: right;
    margin: 10px;
  }

  .nav-name {
    margin: 0px;
  }
}

/* HEADER */

.header {
  background-color: #f3eddf;
}

.header-one {
  vertical-align: middle;
}

.main-title {
  width: fit-content;
  background-color: white;
  text-align: left;
  font-family: 'Source Code Pro', sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  color: #4f4f4f;
  padding: 14px 14px;
}

.subtitle {
  font-size: 16px;
  font-family: 'Poppin', sans-serif;
  font-weight: normal;
  color: #6d6d6d;
}

.headshot {
  width: 100%;
  object-fit: cover;
  position: relative;
  left: 30px;
}

.header-img {
  padding-right: 0;
}

.header-title {
  padding-left: 0;
  position: relative;
  right: 30px;
  top: 20px;
}

@media screen and (max-width: 600px) and (min-width: 481px) {
  .main-title {
    width: fit-content;
    text-align: center;
    margin-top: 0;
  }

  .header-title {
    padding-top: 0;
    position: relative;
    top: -130px;
    margin-bottom: -100px;
  }

  .header-img {
    padding: 20px;
  }

  .headshot {
    width: 100%;
    margin: auto;
    text-align: center;
    position: initial;
  }

}

@media screen and (min-width:601px) and (max-width: 840px) {
  .main-title {
    width: fit-content;
    text-align: center;
    margin-top: 0;
  }

  .header-title {
    padding-top: 0;
    position: relative;
    top: -130px;
    margin-bottom: -100px;
  }

  .header-img {
    padding: 20px;
  }

  .headshot {
    width: 100%;
    margin: auto;
    text-align: center;
    position: initial;
  }
}

@media screen and (max-width: 480px) {
  .main-title {
    width: fit-content;
    text-align: left;
    font-size: 14px;
    margin-top: 0;
  }

  .header-title {
    padding-top: 0;
    position: relative;
    top: -130px;
    margin-bottom: -100px;
  }

  .header-img {
    padding: 20px;
  }

  .headshot {
    width: 100%;
    margin: auto;
    text-align: center;
    position: initial;
  }

}

/* ABOUT */

.code {
  width: 800px;
  margin: auto;
  padding: 20px;
}

.console-top {
  padding: 5px;
  margin: 0 auto;
  background-color: #f8f5ef ;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.console-body {
  margin: 0 auto;
  background-color: #4f4f4f;
  border-end-end-radius: 5px;
  border-end-start-radius: 5px;
}

.console-buttons {
  margin: 0px;
  padding: 5px;
  align-items: center;
  vertical-align: middle;
  overflow: auto;
}

.close, .minimize, .zoom {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin-right: 8px;
  padding: 8px;
  font-size: 8px;
  float: left;
}

.close {
  background-color: #af6055;
}

.minimize {
  background-color: #d39729;
}

.zoom {
  background-color: #9BA6A1;
}

.about-text {
  color: #fff;
  margin: 10px;
  padding: 10px;
  font-family: 'Source Code Pro', monospace;
  text-align: left;
}

.about-answer {
  color: #f8f5efb2;
}

@media screen and (max-width:600px){
  .code {
    width: fit-content;
  }
}

@media screen and (min-width:601px) and (max-width: 880px) {
  .code {
    width: fit-content;
  }
}

.port {
  width: 200px;
  display: block;
  object-fit: cover;
}  

/* PROJECTS */

@media (hover: hover) {

  .card-project:hover :not(img){
    opacity:1;
  }
  
  .card-project:hover img{
    filter:blur(1px) brightness(60%);
  }

  .mobile-links {
    display: none;
  }

}

.mobile-links {
  padding: 10px 0px;
  /* text-align: center;
  align-items: center; */
}

.mobile-links > a {
  display: inline-block;
  width: 35%;
  color: #4f4f4f;
  text-transform: uppercase;
  text-align: center;
  font-size: small;
  font-weight: 600;

}

.mobile-btn {
  width: 50%;
}

.project-btn {
  width: 50%;
  margin: 8px;
  padding: 8px;
  border: 1px solid #4f4f4f;

}

/* BLOGS */

.blog-button {
  padding: 14px;
  background-color: white;
  color: #4f4f4f;
  
}


/* RESUME */

.res-body {
  margin: auto;
  padding: 20px;
  width: 800px;
  height: 600px;
  background-color: white;
}

.scroll {
  height: 580px;
  overflow-x: hidden;
  overflow-y: auto;
  color: #4f4f4f;
  text-align: left;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.scroll hr {
  margin: 0;
}

.scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.resume-title {
  text-transform: uppercase;
}

.duties {
  font-weight: normal;
  letter-spacing: normal;
}

@media screen and (max-width:480px){
  .res-body {
    width: fit-content;
    margin: 20px;
  }
}

@media screen and (min-width:480px) and (max-width: 880px) {
  .res-body {
    width: fit-content;
    margin: 30px;
  }
}

/* CONTACT */ 

.contact-div {
  background-color: #924734;
  width: 800px;
  margin: auto;
  padding: 20px;
}

.contact-title {
  font-family: 'Karla', sans-serif;
  font-size: 32px;
  color: #f3eddf;
  text-align: left;
  vertical-align: middle;
  padding: 0;
  margin: 10px 10px;
}

.contact-pic {
  width: 100%;
  float: right;
}

.contact {
  margin: 10px;
}

.icon {
  color: #f3eddf;
  padding: 10px;
  display: block;
  text-align: center;
}

.icon > a {
  text-decoration: none;
  color: #f3eddf;
}

@media screen and (max-width: 880px) {
  .contact-div {
    width: fit-content;
    margin: 10px;
  }
  .contact-title {
    width: fit-content;
    text-align: center;
  }
}

/* FOOTER */

.footer {
  padding: 10px 30px;
  text-align: left;
  font-size: x-small;

}

.footnav a {
  color: #4f4f4f;
  text-decoration: none;
}

.footnav a:hover {
  -webkit-transform: scale(1.5);
  transform: scale(3.0,3.0);
}

.right {
  float: right;
}

.left {
  float: left;
}

@media screen and (max-width: 880px) {
  .right, .left {
    float: none;
  }

  .footer {
    text-align: center;
  }
}

/* #C1A99A */
/* #AF6D55 */
/* #F0B74D */
/* #EEE2DB */
/* 9BA6A1 */
